import { useEffect, useState, useContext } from "react";
import { 
  CircularProgress, Box, Typography, Grid, Card, CardContent, CardActions, 
  IconButton, Button, Avatar, Menu, MenuItem, Divider, Badge, ImageList, ImageListItem,CardMedia, TextField, Snackbar, Alert, Modal, Stepper, Step, StepLabel 
} from "@mui/material";
import { ShoppingCart, Notifications, Settings, Logout, FitnessCenter, Visibility, Add, ChevronLeft, ChevronRight, Share } from "@mui/icons-material";
import axios from "axios";
import { LocalizationProvider, StaticDatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { UserContext } from "../UserContext"; // Import UserContext
import "./UserDashboardPage.css"; // Import the CSS file
import UserInbox from "./UserInbox";

export default function UserDashboardPage() {
  const { user, logout } = useContext(UserContext); // Access user and logout

  const [featuredPrograms, setFeaturedPrograms] = useState([]);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [loadingSessions, setLoadingSessions] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For dropdown menu
  const open = Boolean(anchorEl);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalBilling, setTotalBilling] = useState(0);
  const [previousMonthSessions, setPreviousMonthSessions] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showProgramDetails, setShowProgramDetails] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);


  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const steps = ["Pick a Date", "Pick a Time", "Thank You!"];

  // Handle Dropdown Menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Fetch Featured Programs
  useEffect(() => {
    const fetchFeaturedPrograms = async () => {
      setLoadingFeatured(true);
      try {
        const response = await axios.get("https://api.cope.ke/subscriptions");
        const filteredPrograms = response.data.filter(
          (program) =>
            program.category === "Individual"
        );
        setFeaturedPrograms(filteredPrograms);
      } catch (error) {
        console.error("Error fetching featured programs:", error);
      } finally {
        setLoadingFeatured(false);
      }
    };
    fetchFeaturedPrograms();
  }, []);

  // Fetch Upcoming Sessions
  // Fetch Upcoming Sessions
  useEffect(() => {
    const fetchUpcomingSessions = async () => {
      setLoadingSessions(true);
      try {
        const response = await axios.get(
          `https://api.cope.ke/api/therapy  /${user.user_id}`
        );
        setUpcomingSessions(response.data);

        // Count sessions from the previous month
        const currentMonth = new Date().getMonth();
        const previousMonthCount = response.data.filter((session) => {
          const sessionDate = new Date(session.session_date);
          return sessionDate.getMonth() === currentMonth - 1;
        }).length;
        setPreviousMonthSessions(previousMonthCount);
      } catch (error) {
        console.error("Error fetching upcoming sessions:", error);
      } finally {
        setLoadingSessions(false);
      }
    };
    fetchUpcomingSessions();
  }, [user.user_id]);

// Fetch Recent Transactions and Calculate Total Billing
useEffect(() => {
  const fetchRecentTransactions = async () => {
    setLoadingTransactions(true);
    try {
      const response = await axios.get(
        `https://api.cope.ke/user-billing/${user.user_id}`
      );
      setRecentTransactions(response.data);

      // Calculate the total billing amount
      const total = response.data.reduce((sum, transaction) => {
        const plans = JSON.parse(transaction.plans); // Parse the JSON string in "plans"
        return sum + plans.reduce((planSum, plan) => planSum + plan.price, 0);
      }, 0);
      setTotalBilling(total);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error fetching recent transactions.",
        severity: "error",
      });
    } finally {
      setLoadingTransactions(false);
    }
  };
  fetchRecentTransactions();
}, [user.user_id]);

  // Handle Live Search
   // Handle Search
   const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = featuredPrograms.filter((program) =>
      program.plans.some(
        (plan) =>
          plan.plan_type.toLowerCase().includes(searchTerm) ||
          plan.price.toString().includes(searchTerm)
      )
    );
    setFilteredPrograms(filtered);
  };  

  // Carousel Settings
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false, // Prevents cards from showing partially
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "red",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: "teal",
          borderRadius: "50%",
          margin: "5px auto",
        }}
      />
    ),
  };

  // Open the modal
  const handleOpen = () => {
    setOpenModal(true);
  };

  // Close the modal
  const handleClose = () => {
    setOpenModal(false);
    setActiveStep(0); // Reset to the first step
  };

  // Handle step navigation
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleClose(); // Close modal on the final step
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleBackFeaturedPrograms = () => {
    setSelectedProgram(null);
    setShowProgramDetails(false);
  };

  const handleViewProgram = (program) => {
    setSelectedProgram(program);
    setShowProgramDetails(true);
  };

  
  // Handle form submission
  const submitAppointmentRequest = async () => {
    if (!appointmentDate || !appointmentTime) {
      setSnackbar({
        open: true,
        message: "Please select a valid date and time.",
        severity: "error",
      });
      return;
    }

    const requestData = {
      name: user.name,
      email: user.email,
      user_id: user.id,
      phone: user.phone,
      appointment_date: appointmentDate.toISOString(),
      appointment_time: appointmentTime.toISOString(),
    };

    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/book_appointment", requestData);
      setSnackbar({
        open: true,
        message: "Appointment requested successfully!",
        severity: "success",
      });
      setActiveStep(2); // Move to the thank-you step
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to request an appointment.",
        severity: "error",
      });
      console.error("Error booking appointment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleShare = (program) => {
    const shareData = {
      title: program.plan,
      text: `Check out this amazing program: ${program.plan}`,
      url: window.location.href, // You can modify the URL to a specific page
    };

    if (navigator.share) {
      navigator.share(shareData).catch((error) => console.error('Error sharing program:', error));
    } else {
      alert('Sharing is not supported on your browser.');
    }
  };


  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "#f9f9f9" }}>
      {/* Header */}
      

      {/* Main Content */}
      <Box sx={{ padding: "20px" }}>
        {/* Welcome Section */}
        <Box sx={{ marginBottom: 4 }}>
          <Typography color="#666">Explore our wellness programs and services</Typography>
          <Divider sx={{ marginTop:2 }}/>
        </Box>
        
        {/* Dashboard Cards */}
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography color="#00695C" variant="h5">Book Appointment</Typography>
                <Box display="flex" justifyContent="left" alignItems="center">
                <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={handleOpen}
            sx={{
              textTransform: "none",
              borderColor: "#006B54",
              "&:hover": { borderColor: "#004d40"},
            }}
          >
            Book
          </Button>
          
                </Box>
                <Typography color="#666" variant="body2" >
                  Book appointment with licensed practioners
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography color="#00695C" variant="h5">Therapy Sessions</Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {upcomingSessions.length} Active Programs
                </Typography>
                <Typography  variant="body2" color="#666">
                  {previousMonthSessions} from last month
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography color="#00695C" variant="h5">Financial Overview</Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Ksh {totalBilling.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="#666">
                  Current balance
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop:2, marginBottom: 3}}/>
                
        {/* Featured Programs */}
        <Box sx={{ marginTop: 4 }}>
      {/* Header with Search */}
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="center" gap={2}>
        <Typography
          color="#00695C"
          variant="h4"
          sx={{ fontSize: { xs: "18px", sm: "24px" }, flex: 1 }}
        >
          Featured Wellness Programs
        </Typography>
        
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search programs"
          size="small"
          sx={{ width: { xs: "100%", sm: "300px" } }}
        />
      </Box>
      <Divider sx={{ marginTop:2, marginBottom: 3}}/>

      <Box>
        {showProgramDetails && selectedProgram ? (
          // Details view of the selected program
          <Box>
            <Box display="flex" alignItems="center" sx={{ padding: "10px" }}>
              <IconButton onClick={handleBackFeaturedPrograms}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h6" color="primary">
                {selectedProgram.plan}
              </Typography>
            </Box>
            <Box sx={{ padding: "20px" }}>
              <Typography variant="h6" color="#006B54" gutterBottom>
                {selectedProgram.plan}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "16px" }}>
                {selectedProgram.description}
              </Typography>
              <CardMedia
                component="img"
                height="200"
                image={selectedProgram.image || "/placeholder.svg"}
                alt={selectedProgram.plan}
                sx={{ borderRadius: "8px" }}
              />
            </Box>
          </Box>
        ) : (
          // Render carousel if no program is selected
          <Box>
        {/* Carousel */}
      {loadingFeatured ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <div className="slider-container" sx={{ overflow: "hidden", padding: "20px", }}>
        <Slider  {...sliderSettings}>
          {featuredPrograms.map((program, index) => (
            
            <Box key={index} sx={{ padding: "5px" }}>

<Card
              key={program.id}
              sx={{
                width: '80%',
                margin: "0 15px",
                boxShadow: 3,
                "&:hover": { boxShadow: 6, transform: "scale(1.03)" },
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
              }}
            >
              <Box sx={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={program.image || "/placeholder.svg"}
                          alt={program.plan}
                          sx={{ borderRadius: "4px 4px 0 0" }}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
                          }}
                          onClick={() => handleShare(program)}
                        >
                          <Share />
                        </IconButton>
                      </Box>
              <CardContent>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography textAlign='center' variant="h6" sx={{ color: "#006B54", fontWeight: "bold" }}>
                    {program.plan}
                  </Typography>
                </Box>
                <Typography textAlign='center' variant="body2" color="#666">
                  {program.description}
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                <Button
            variant="outlined"
            startIcon={<Visibility />}
            onClick={() => handleViewProgram(program)}
            sx={{
              textTransform: "none",
              borderColor: "#006B54",
              "&:hover": { borderColor: "#004d40"},
            }}
          >
            View
          </Button>
          
                </Box>
                
              </CardContent>
            </Card>
            </Box>
          ))}
        </Slider>
  </div>
      )}
      </Box>
        )}
        </Box>
    </Box>
    <Divider sx={{ marginTop:2, marginBottom: 2}}/>
{/* Upcoming Sessions and Recent Transactions */}
      {upcomingSessions.length > 0 && (
        <Grid container spacing={3} sx={{ marginTop: 4 }}>
        {/* Upcoming Sessions */}
          <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ color: "#00695C", marginBottom: 2 }}>
                Upcoming Sessions
              </Typography>
              {loadingSessions ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  {upcomingSessions.map((session) => (
                    <Box
                      key={session.session_id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ marginBottom: 2 }}
                    >
                      <Box>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {session.session_type}
                        </Typography>
                        <Typography variant="body2" color="#666">
                          {session.session_date}
                        </Typography>
                      </Box>
                      <Button variant="outlined" size="small">
                        Join
                      </Button>
                    </Box>
                  ))}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Recent Transactions */}
        {recentTransactions.length > 0 && (
          <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ color: "#00695C", marginBottom: 2 }}>
                Recent Transactions
              </Typography>
              {loadingTransactions ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  {recentTransactions.map((transaction) => (
                    <Box
                      key={transaction.billing_id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ marginBottom: 2 }}
                    >
                      <Box>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {transaction.plans[0]}
                        </Typography>
                        <Typography variant="body2" color="#666">
                          {transaction.billing_date}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        ${transaction.amount}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        )}
        </Grid>
      )}
    
      </Box>
      {/* Modal */}
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={appointmentDate}
                onChange={(newValue) => setAppointmentDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
                disablePast
              />
            </LocalizationProvider>
          )}

          {activeStep === 1 && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Pick a Time"
                value={appointmentTime}
                onChange={(newValue) => setAppointmentTime(newValue)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
              <Box textAlign="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitAppointmentRequest}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </LocalizationProvider>
          )}

          {activeStep === 2 && (
            <Typography variant="h6" textAlign="center" color="success.main">
              Thank you for booking your appointment!
            </Typography>
          )}

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              startIcon={<ChevronLeft />}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
            {activeStep < steps.length - 1 && (
              <Button
                endIcon={<ChevronRight />}
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
              {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>

    </Box>

    
  );
  
}


